export const en = {
  login: {
    title: "Login at Ma'arej",
    form: {
      email: {
        label: 'Email address',
        required: 'Email is required',
        valid: 'Email must be a valid email address',
      },
      password: {
        label: 'Password',
        required: 'Password is required',
      },
    },
  },
  button: {
    back: 'Back',
    login: 'Login',
  },
};
