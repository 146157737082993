export const ar = {
  title: {
    campaign_milestone: 'إنجاز الحملة',
    add_milestone: 'أضف معلمًا',
    anonymous_donor: 'متبرع مجهول',
    donor: 'متبرعين',
    is_on_the_way: 'هو في الطريق',
    pending: 'قيد الانتظار',
    completed: 'مكتمل',
    milestone_details: 'تفاصيل معلم',
    new_progress: 'تقدم جديد',
    progresses: 'يتقدم',
    new_invoice: 'أضف فاتورة جديدة',
    detail_invoice: 'تفاصيل فاتورة',
  },
  button: {
    add_milestone: 'إضافة معلم',
    add_campaign: 'أضف حملة',
    submit_milestone: 'إرسال معلم رئيسي',
    edit_campaign: 'حملة تحرير',
    request_submit_campaign: 'طلب تقديم الحملة',
    request_submit_item: 'طلب إرسال عنصر المشروع',
    save_campaign: 'حفظ الحملة',
    apply_for_campaign: 'تقدم بطلب للحصول على هذه الحملة',
    accept_this_vendor: 'قبول هذا البائع',
    reject_this_vendor: 'رفض هذا البائع',
    milestones: {
      view_invoices: 'عرض الفواتير',
      add_progress: 'أضف تقدمًا',
      view: 'عرض معلم',
      close_view: 'عرض قريب',
      submit_progress: 'تقديم التقدم',
      cancel: 'يلغي',
      check_progress: 'التحقق من التقدم',
      view_progress: 'عرض التقدم',
      view_progresses: 'عرض التقدم',
      confirm_progress: 'تأكيد التقدم',
      reject_progress: 'رفض التقدم',
      submit_invoice: 'إرسال الفاتورة',
      view_payment: 'عرض الدفع',
    },
  },
  form: {
    name: {
      label: 'اسم الحملة',
      required: 'اسم الحملة مطلوب',
    },
    project_id: {
      label: 'حدد المشروع الأصلي',
      required: 'مطلوب المشروع الأصلي',
    },
    campaign_type_id: {
      label: 'نوع الحملة',
      required: 'نوع الحملة مطلوب',
    },
    only_money: {
      label: 'حملة المال فقط',
      required: 'الحملة مطلوبة فقط المال',
      min_1: 'الحملة فقط المال يكون أكبر من 0',
    },
    vendor_income: {
      label: 'دخل المقاول',
      required: 'مطلوب دخل المقاول',
      min_1: 'أن يكون دخل المقاول أكبر من 0',
      max_only_money: 'يجب أن يكون دخل المقاول متساويًا أو أقل من مبلغ المال فقط',
    },
    images: {
      label: 'تحميل الملفات',
      required: 'مطلوب تحميل الملفات',
      max_files: 'سمحت فقط لتحميل 3 ملفات',
    },
    milestone_name: {
      label: 'اسم المعلم',
      required: 'اسم الحدث الرئيسي مطلوب',
    },
    process_name: {
      label: 'اسم التقدم',
      required: 'اسم التقدم مطلوب',
    },
    deadline: {
      label: 'موعد التسليم',
      required: 'الموعد النهائي مطلوب',
    },
    details: {
      label: 'تفاصيل',
      required: 'التفاصيل مطلوبة',
    },
    progress_title: {
      label: 'عنوان التقدم',
      required: 'عنوان التقدم مطلوب',
    },
    milestone: {
      label: 'منعطف',
    },
    type: {
      label: 'يكتب',
    },
    amount: {
      label: 'كمية',
    },
    transaction_code: {
      label: 'رمز العملية',
    },
    date: {
      label: 'تاريخ',
    },
    donor_name: {
      label: 'اسم المانح',
    },
    donation: {
      label: 'هبة',
    },
    counts: {
      label: 'العد',
    },
    email: {
      label: 'بريد إلكتروني',
    },
    vendor_name: {
      label: 'اسم البائع',
    },
    invoice_name: {
      label: 'اسم الفاتورة',
      required: 'اسم الفاتورة مطلوب',
    },
    invoice_amount: {
      label: 'كمية',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'المبلغ مطلوب',
      remaining_max: 'يجب أن يكون المبلغ الخاص بك أقل من',
    },
    bank_id: {
      label: 'اسم البنك',
      required: 'مطلوب اسم البنك',
    },
    bank_account_name: {
      label: 'اسم الحساب المصرفي',
      required: 'اسم الحساب المصرفي مطلوب',
    },
    bank_account_number: {
      label: 'رقم الحساب المصرفي',
      required: 'رقم الحساب المصرفي مطلوب',
      min: 'يجب أن يكون رقم الحساب المصرفي 22 رقمًا من الأرقام',
    },
  },
  options: {
    donors: {
      started_from: 'بدأت من',
      ends_to: 'ينتهي',
      money_donated: 'تبرعت المال',
      count_donated: 'التبرع',
    },
  },
};
