import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

import { PATHS_DASHBOARD } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const OverviewEvaluatorPage = lazy(() => import('src/pages/evaluator/statistics'));

// Maintenance Requests
const MaintenanceRequestListPage = lazy(() => import('src/pages/maintenance-requests/all'));
const DetailsMaintenanceRequestPage = lazy(() => import('src/pages/maintenance-requests/details'));
const MainreqEvaluationTaskListPage = lazy(
  () => import('src/pages/maintenance-requests/my-evaluation-task')
);
const MainreqDetailEvaluationTaskPage = lazy(
  () => import('src/pages/maintenance-requests/detail-evaluation-task')
);

// Settings
const SettingsAccountInfoPage = lazy(() => import('src/pages/settings/account-info'));

// ----------------------------------------------------------------------

const identifiedPathsV2 = PATHS_DASHBOARD('EVALUATOR');

// ----------------------------------------------------------------------

export const evaluatorRoutes = {
  path: 'evaluator',
  element: (
    <AuthGuard>
      <RoleBasedGuard hasContent roles={['EVALUATOR']} sx={{ py: 10 }}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to="/evaluator/dashboard/statistics" replace />, index: true },
    {
      path: 'dashboard',
      children: [
        { element: <Navigate to="/evaluator/dashboard/statistics" replace />, index: true },
        {
          path: 'statistics',
          element: (
            <OverviewEvaluatorPage
              title="menu.dashboard"
              activeRole="roles.EVALUATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'maintenance-request',
      children: [
        { element: <Navigate to="/evaluator/maintenance-request/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <MaintenanceRequestListPage
              title="section.maintenance_request.caption.all_evaluation_request"
              activeRole="roles.EVALUATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/evaluator/maintenance-request/all" replace />, index: true },
            {
              path: ':maintenance_request_id',
              element: (
                <DetailsMaintenanceRequestPage
                  title="section.maintenance_request.caption.detail_evaluation_request"
                  activeRole="roles.EVALUATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
        {
          path: 'my-evaluation-task',
          children: [
            {
              path: '',
              element: (
                <MainreqEvaluationTaskListPage
                  title="section.maintenance_request.caption.my_evaluation_task"
                  activeRole="roles.EVALUATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: 'detail',
              children: [
                {
                  element: (
                    <Navigate to="/evaluator/maintenance-request/my-evaluation-task" replace />
                  ),
                  index: true,
                },
                {
                  path: ':evaluator_apply_request_id',
                  element: (
                    <MainreqDetailEvaluationTaskPage
                      title="section.maintenance_request.caption.evaluation"
                      activeRole="roles.EVALUATOR"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'settings',
      children: [
        { element: <Navigate to="/evaluator/settings/account-info" replace />, index: true },
        {
          path: 'account-info',
          element: (
            <SettingsAccountInfoPage
              title="menu.settings.account_info"
              activeRole="roles.EVALUATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
  ],
};
