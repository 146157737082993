export const ar = {
  login: {
    title: 'تسجيل الدخول في معراج',
    form: {
      email: {
        label: 'عنوان البريد الإلكتروني',
        required: 'البريد الالكتروني مطلوب',
        valid: 'يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا',
      },
      password: {
        label: 'كلمة المرور',
        required: 'كلمة المرور مطلوبة',
      },
    },
  },
  button: {
    back: 'خلف',
    login: 'تسجيل الدخول',
  },
};
