import * as Sentry from '@sentry/react';
import { AuthModuleApi, UserModuleApi } from '@otarid/ommar-sdk';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { getStorage, setStorage, removeStorage } from 'src/hooks/use-local-storage';

import axios, { endpoints } from 'src/utils/axios';
import { workerApisConfiguration } from 'src/utils/worker-configuration';

// import { HOST_API } from 'src/config-global';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const activeRole = getStorage('activeRole');
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const userApi = new UserModuleApi(workerApisConfiguration);

        const { data, status } = await userApi.validateToken({
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-chosen-role': activeRole,
          },
        });

        // const { data, status } = await axios.get(`${HOST_API}/users/fetch-current`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        // });

        const response = data as unknown as any;
        const userTemp = response.data as AuthUserType;

        if (status === 200 && userTemp) {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: {
                ...userTemp,
                activeRole: userTemp?.roles[0],
                accessToken,
              },
            },
          });

          setStorage('activeRole', userTemp?.roles[0]);

          Sentry.setUser({
            id: userTemp.id,
            username: userTemp.full_name,
            email: userTemp.email,
          });
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (login_id: string, password: string) => {
    const authApi = new AuthModuleApi(workerApisConfiguration);

    const { data, status } = await authApi.managerialLogin({
      LoginDto: {
        login_id,
        password,
        selectLang: 'ar',
      },
    });

    const response = data as unknown as any;
    const userTemp = response.data as AuthUserType;

    if (status === 200 && userTemp) {
      setSession(userTemp.accessToken);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...userTemp.user,
            activeRole: userTemp.user?.roles[0],
            accessToken: userTemp.accessToken,
          },
        },
      });

      setStorage('activeRole', userTemp.user?.roles[0]);

      Sentry.setUser({
        id: userTemp.user.id,
        username: userTemp.user.full_name,
        email: userTemp.user.email,
      });
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });

    removeStorage('activeRole');

    Sentry.setUser(null);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
