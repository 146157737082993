import { en as donor } from '../donor/translation/en';
import { en as admin } from '../admin/translation/en';
import { en as ticket } from '../ticket/translation/en';
import { en as vendor } from '../vendor/translation/en';
import { en as project } from '../project/translation/en';
import { en as operator } from '../operator/translation/en';
import { en as campaign } from '../campaign/translation/en';
import { en as settings } from '../settings/translation/en';
import { en as evaluator } from '../evaluator/translation/en';
import { en as marketplace } from '../marketplace-item/translation/en';
import { en as maintenance_request } from '../maintenance/translations/en';

export const en = {
  donor,
  admin,
  ticket,
  vendor,
  project,
  operator,
  campaign,
  settings,
  evaluator,
  marketplace,
  maintenance_request,
};
