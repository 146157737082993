import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#DAFFEC',
  light: '#6B8D7B',
  main: '#476455',
  dark: '#345343',
  darker: '#294536',
  contrastText: '#FFFFFF',
  darkerText: '#345343',
};

export const secondary = {
  lighter: '#FFF2DA',
  light: '#F1C56E',
  main: '#E4AF46',
  dark: '#DBA335',
  darker: '#CF992E',
  contrastText: '#FFFFFF',
  darkerText: '#DBA335',
};

export const info = {
  lighter: '#C6A182',
  light: '#BA8E69',
  main: '#AD7A50',
  dark: '#966945',
  darker: '#7D5839',
  contrastText: '#FFFFFF',
  darkerText: '#7D5839',
};

export const success = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#06C270',
  dark: '#05A660',
  darker: '#05A660',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FDED72',
  light: '#FDDD48',
  main: '#FFCC00',
  dark: '#E5B800',
  darker: '#E5B800',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF3B3B',
  dark: '#E53535',
  darker: '#E53535',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[600], 0.1),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
